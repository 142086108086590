@import '../../shared/settings';

.domain-placeholder {
    padding: 76px 0;
    text-align: center;

    svg {
        width: 100%;
        max-width: 716px;
        height: auto;
    }

    .domain-placeholder-inner {
        display: inline-block;
        margin-bottom: 98px;
        border: 1px solid rgba($light-grey, 0.5);
        border-radius: 8px;
        padding: 48px 128px;
        max-width: 554px;

        h1 {
            margin: 0 0 12px;
            line-height: 36px;
            font-size: 26px;
        }

        p {
            margin: 32px 0;
            line-height: 28px;
            color: $dark-grey;
            font-size: 18px;

            h1 + & {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                transition: color $fast;
                text-decoration: underline;
                color: $dark-grey;

                &:hover {
                    transition: color $fast;
                    color: $brand;
                }
            }

            small {
                display: block;
                line-height: 24px;
                color: $dark-grey;
                font-size: 14px;
            }
        }
    }

    @include respond-to(mobile) {
        position: relative;
        padding: 20px 20px;
        overflow: hidden;
        height: calc(100vh - 56px - 40px);

        svg {
            position: absolute;
            left: 20px;
            bottom: 48px;
            width: 412px;
            height: 214px;
            z-index: -1;
        }

        .domain-placeholder-inner {
            padding: 32px 20px;
            margin: 0;
            background-color: white;

            h1 {
                line-height: 30px;
                font-size: 20px;
            }

            p {
                line-height: 26px;
                font-size: 16px;

                small {
                    margin-top: 24px;
                }
            }
        }
    }
}

.zupr-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    height: calc(100vh - 97px);
    text-align: center;

    a {
        color: $brand;
    }

    .zupr-placeholder-inner {
        padding: 0 34px;

        svg {
            transition: width 0.5s;
            width: 159px;
            height: 67px;
            .logomark,
            .wordmark {
                fill: var(--brand);
            }
        }

        h1 {
            transition: font-size 0.5s;
            margin: 32px 0 0;
            line-height: 40px;
            color: var(--brand);
            font-size: 26px;
        }

        p {
            margin: 14px auto 0;
            max-width: 400px;
            line-height: 26px;
            font-size: 16px;
        }

        @include respond-to(mobile) {
            svg {
                transition: max-width 0.5s;
                width: 118px;
                height: 50px;
            }

            h1 {
                transition: width 0.5s;
                margin: 22px 0 0;
                line-height: 28px;
                font-size: 18px;
            }

            p {
                line-height: 24px;
                font-size: 14px;
            }
        }
    }
}
